import React from "react";
// import { Link } from "gatsby"

import { Heading, Container, List, ListItem, ListIcon } from "@chakra-ui/react";

// import { MdCheckCircle, MdSettings } from "@chakra-ui/icons";
import { FiAward } from "react-icons/fi";

import Layout from "../components/layout";
import SEO from "../components/seo";
// import Map from "../components/mapComponent";

const MissionPage = () => (
  <Layout>
    <SEO title="Mission statement" />

    {/* <Stack w="80%" mx="auto" direction={["column", "row"]} spacing="2rem"> */}
    <Container
      p="2rem"
      // h="400px"
      bg="gray.100"
      borderRadius="xl"
      centerContent
    >
      <Heading>Our mission!</Heading>
      {/* <Text mt={4} fontSize="xl">
        Inform the world about the dangers of the Bearbat.
      </Text> */}

      <List spacing={3}>
        <ListItem>
          <ListIcon as={FiAward} color="green.500" />
          Inform the world about the dangers of the Bearbat.
        </ListItem>
        <ListItem>
          <ListIcon as={FiAward} color="green.500" />
          Collect findings ans clues about this illusive beast.
        </ListItem>
        {/* You can also use custom icons from react-icons */}
        <ListItem>
          <ListIcon as={FiAward} color="green.500" />
          Secret only for the Initiated
        </ListItem>
      </List>
    </Container>
    {/* </Stack> */}

    {/* <Link to="/page-2/">Go to page 2</Link> <br /> */}
  </Layout>
);

export default MissionPage;
